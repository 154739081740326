const experience = [
{
    key: 1,
    title: "Software Developer",
    date: "Dec 2021 - Jun 2023",
    company: "Silverpine",
    location: "Portland, OR",
    description: "This role involved the conception, development, and construction of a mechanical test fixture, purpose-built to rigorously assess product performance through iterative testing cycles across a local network. The orchestration of an ASP.NET framework alongside AWS technologies was instrumental in the establishment of an efficient and seamless test logging pipeline. This pipeline seamlessly harvested data generated during the cycle testing, channeling it meticulously into a SQL database for systematic analysis. Beyond this, a pivotal aspect was the comprehensive automated testing approach encompassing hardware devices, databases, UI elements, APIs, mobile applications, and backend services. Moreover, a significant facet of the role lay in the ability to coordinate with a  seasoned team of software developers, ensuring collective efforts were aligned with overarching objectives.",
    noted: ["Assisted in test case creation during sprints, to create a test suite including cross-device testing, regression testing, accessibility testing, and unit testing", "Python script creation to compile field test data from multiple testing locations saving sixteen hours monthly", "On-site hardware support of locks, flashing firmware, and collection of thousands of data points across multiple fields test locations", "Joined project during development, was able to quickly get up to speed and contribute to three projects simultaneously"]
},
{
    key: 2,
    title: "Software Engineer",
    date: "Dec 2020 - Jul 2021",
    company: "Raptor Technica",
    location: "Portland, OR",
    description: "Played a pivotal role as a crucial member in QA test automation, where Python scripts were skillfully developed to streamline web unit tests utilizing the Selenium library. This required close coordination with the development team to pinpoint and document defects using project management tools. Collaborative efforts extended to crafting detailed test cases, ultimately resulting in the establishment of an extensive test suite. The responsibilities included conducting quality assurance testing for a computer power management website and its related client software, deploying across a varied client base, and supporting a multitude of distinct devices in the software development domain.",
    noted: ["Directed quality assurance plan, management, deployment, documentation, test case creation, and coordinated with development team", "Authored Python scripts for web unit tests using Selenium library"]
},
{
    key: 3,
    title: "Software Developer",
    date: "Sep 2018 - Jun 2020",
    company: "Sanddollar Labs",
    location: "Newport, OR ",
    description: "Through close collaboration with project leads, test cases were meticulously formulated and executed, ensuring comprehensive test coverage across various functionalities. Active involvement extended to aiding in developing APIs and backend services, fostering seamless system integration. Integral to the role was the meticulous manual assessment of mobile applications, facilitating interaction with hardware locks via Bluetooth. A structured approach to defect reporting was established. Regular execution of regression testing added an additional layer of quality control, contributing to the overall stability and functionality of the systems.",
    noted: ["Developed Cloud backend and APIs","Assisted in the creation of Cloud Identity and Access Management roles"]
}]

export default experience;