import React from "react";
import Header from "./Header";
import About from "./About"
import TechStack from "./TechStack"
import Education from "./Education";
import experience from "../WorkExpreince";
import Experience from "./Experience";
import education from "../Education"
import VisitorCount from "./VisitorCount";

function App(){
    return (
        <div>
            <Header/>
            <About/>
            <TechStack/>
            <div class="section">
            <h3 class="title">PROFESSIONAL EXPERIENCE</h3>
            </div>

            {experience.map((exer, index) => {
                return <Experience key={index} title={exer.title} date={exer.date} location={exer.location} company={exer.company} description={exer.description} noted={exer.noted}/>
            })}

            <div class="section"><h3 class="title">EDUCATION & CERTIFICATIONS</h3>
            {education.map((ed, index) => {
                return <Education key={index} name={ed.name} url={ed.url}/>
            })}
            
            </div>

            <VisitorCount/>
        </div>
    );
}

export default App;