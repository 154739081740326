import React from "react";

function Experience(props){
    return(
        <div class="section">
            <h4 class="header">{`${props.company}, ${props.location} - ${props.title}`}</h4>
            <h4 class="date">{props.date}</h4>
            <p>{props.description}</p>
            <ul class="noted"><h4>Noted Accomplishments:</h4>
                {props.noted.map((note) => {
                    return <li>{note}</li>
                })}
            </ul>
            
        </div>
    )
}

export default Experience;