import React from "react";

function TechStack(){
    return(
        <div class="section">
            <h3 class="title">TECHNICAL PROFICIENCIES</h3>
            <p  id="techStack">Python | C# | Javascript | AWS - Kinesis, Lambda, S3, RDS, Redshift, EMR, Glue, etc. | NodeJS ReactJS JQuery | SQL  NoSql | MongoDB | Postgres | APIs | Linux | Terraform | Docker | MongoDB | Git | Github Actions | Selenium Appium | Postman Swagger</p>
        </div>
    )
}

export default TechStack;