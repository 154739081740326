import React from "react";
import { useEffect } from "react";
import { useState } from "react";



function VisitorCount(){
    const [visitorCount, setVisitorCount] = useState();

    useEffect(() => {
        fetch("https://kkoti48id8.execute-api.us-west-2.amazonaws.com/default/lambda_visitor_count", {method : "GET"})
        .then((res) => res.json())
        .then((data) => {
            setVisitorCount(data["count"])
        })
    }, [])

    return(
        <div class="section">
            <h3 class="title">VISITOR COUNT</h3>
            <h2 id="visitorCount"> You Are The {visitorCount} Visitor!</h2>
        </div>
    )
}

export default VisitorCount;