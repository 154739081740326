import React from "react";
import LinkedInIcon from '@mui/icons-material/LinkedIn';

function Header(){
    return(
        <nav>
                <h1>Hunter Vertner
                    <div class="icons">
                        <a href="https://www.linkedin.com/in/huntervertner/" target="_blank" rel="noreferrer"><LinkedInIcon/></a>
                    </div>
                </h1>
        </nav>
    );
}

export default Header;