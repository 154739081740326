
const education = [{
    name: "AWS Certified Solutions Architect Associate",
    url: "https://www.credly.com/badges/f403f762-af03-4ace-9d1d-9ecf3a16db67/public_url"
}, 
{
    name: "AWS Certified Developer Associate",
    url: "https://www.credly.com/badges/716747a1-9f8d-43d8-ade6-95253f6930d3/public_url"

},
{
    name: "AWS Certified Cloud Practitioner",
    url: "https://www.credly.com/badges/a8d54cf8-e823-437c-8288-3628c89b09c0/public_url"
},
{
    name: "Associate Degree in General Studies, Oregon Coast Community College, Newport, OR",
    url: ""
}]

export default education