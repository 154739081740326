import React from "react";

function About(){
    return(
        <div class="section">
            <h3 class="jobTitle">Software Developer</h3>
            <h4 class="subtitle">Innovative and dedicated  Software Developer with substantial experience in software development, project development,  and cloud platforms.</h4>
            <p>Experienced Software Developer with a passion for creating seamless digital experiences. Equipped with strong proficiencies across front-end and back-end technologies, adept at translating creative ideas into functional code. Demonstrated ability to architect robust server structures, design captivating user interfaces, and ensure quality products. Committed to maintaining clean code, responsive design, and staying updated with industry trends and best practices. Excited to contribute expertise to innovate and craft dynamic solutions for impactful results</p>
        </div>
    )
}

export default About;